@font-face {
    font-family: 'Futuris';
    src: url('Futuris-Bold.eot');
    src: url('Futuris-Bold.eot?#iefix') format('embedded-opentype'),
        url('Futuris-Bold.woff2') format('woff2'),
        url('Futuris-Bold.woff') format('woff'),
        url('Futuris-Bold.ttf') format('truetype'),
        url('Futuris-Bold.svg#Futuris-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pilcrow';
    src: url('Pilcrow-Semibold.eot');
    src: url('Pilcrow-Semibold.eot?#iefix') format('embedded-opentype'),
        url('Pilcrow-Semibold.woff2') format('woff2'),
        url('Pilcrow-Semibold.woff') format('woff'),
        url('Pilcrow-Semibold.ttf') format('truetype'),
        url('Pilcrow-Semibold.svg#Pilcrow-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}